import { React, useEffect, useRef, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import HeaderProfileArea from './shared/HeaderProfileArea';
import { ButtonLoading } from './shared/Buttonloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { createTweet, resetCreateTweet, getActiveBotOperators, resetGetBotOperators, resetActiveBotOperatorsFailed } from '../actions/twitterAction';
import CreatePostimg from '../assets/images/art.png';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';
import Exclusive from '../assets/images/exclusive.png'

const CreatePost = () => {
      
      const dispatch = useDispatch();
      const navigate = useNavigate();

      const createTweetRes = useSelector(state => state.twitterReducer.createTweet);
      const getActiveBotOperatorRes = useSelector(state => state.twitterReducer.getActiveBotOperator);
      const getActiveBotOperatorFailed = useSelector(state => state.twitterReducer.getActiveBotOperatorFailed);

      const [key, setKey] = useState('Twitter');

      const [twitterBotList, setTwitterBotList] = useState([]);

      const [botselectors, setBotselectors] = useState([]);

      const [loadingTwnBtn, setLoadingTwnBtn] = useState(false);

      const [tweetTextInput, setTweetTextInput] = useState("");

      const select_bot_op_multi = true;

      useEffect(() => {
            dispatch(getActiveBotOperators());
      },[]);

      useEffect(() => {
            if(getActiveBotOperatorFailed.statusCode == 401){
                  dispatch(resetActiveBotOperatorsFailed());
                  toast.error(getActiveBotOperatorFailed.message, {autoClose:6000,onClose: () => {
                        dispatch(emptyRedux());
                        logout();
                        navigate("/login");
                  }});
            }
      },[getActiveBotOperatorFailed]);

      useEffect(() => {
            if (createTweetRes.statusCode == 200) {
                  let createTweetData = createTweetRes.response;
                  console.log(createTweetData);
                  setTimeout(() => {
                        dispatch(resetCreateTweet());
                        // setFetched(true);
                  }, 1500);
                  setLoadingTwnBtn(false);
                  setTweetTextInput("");
                  setBotselectors([]);
                  toast.success('Successfully posted new tweet!', {autoClose:3000});
            }
      }, [createTweetRes]);
      
      useEffect(() => {
            if (getActiveBotOperatorRes.statusCode == 200) {
                  console.log(getActiveBotOperatorRes.data.users);
                  let botlist = getActiveBotOperatorRes.data.users;
                  const newArray = botlist.map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });
                  setTwitterBotList(newArray);
            }
      }, [getActiveBotOperatorRes]);

      const handleBotOperatorChanges = (options) => {
            
            let value = [];
            for (var i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            
            setBotselectors([
                  ...value
            ]);
      }

      const handleTweetTextChanges = (event) => {
            var value = event.target.value;
            
            setTweetTextInput(value);
      }
      
      const handleTweetSubmit = (event) => {
            event.preventDefault();

            setLoadingTwnBtn(true);

            const tweetValue = event.target.elements.tweet.value;
            if(tweetValue == ""){
                  // console.log('add tweet message');
                  toast.error("Tweet message should not be empty.", {autoClose:5000});
                  setLoadingTwnBtn(false);
                  return;
            }
            // console.log(tweetValue);
            // console.log(botselectors)

            if(botselectors.length > 0){
                  botselectors.map((item) => { 
                        let data = {
                              "tweet_message": tweetValue,
                              "bot_id": item
                        }
                        dispatch(createTweet(data));
                  });
            }
            else{
                  toast.error("Please select any users to post the tweet.", {autoClose:5000});
                  setLoadingTwnBtn(false);
            }
      }


      const animatedComponents = makeAnimated();

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft">
                                    <h3>Create Post</h3>
                              </div>
                              <HeaderProfileArea/>
                        </div>
                  </header>
                  <div className="main_content innermain">
                        <div className="create_post">
                              <Tabs
                                    defaultActiveKey="profile"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                              >
                                    <Tab eventKey="Twitter" title="Twitter">
                                          <form onSubmit={handleTweetSubmit} className='fadein'>
                                                <div className="tab-cnt">
                                                      <div className="frm">
                                                            <textarea name="tweet" placeholder='Type post description here' value={tweetTextInput} onChange={handleTweetTextChanges} />
                                                      </div>
                                                      <div className="frm">
                                                            <Select
                                                                  closeMenuOnSelect={true}
                                                                  components={animatedComponents}
                                                                  isMulti={select_bot_op_multi}
                                                                  options={twitterBotList}
                                                                  onChange={handleBotOperatorChanges}
                                                            />
                                                      </div>
                                                </div>
                                                <button type="submit" className={"send_btn " + (loadingTwnBtn ? "btn_loading" : "")}>
                                                      <span> Tweet</span>
                                                      <ButtonLoading />
                                                </button>
                                          </form>
                                    </Tab>
                                    <Tab eventKey="Facebook" title="Facebook">
                                          <div className='csitxt fadein'>
                                                <img src={CreatePostimg} alt="" />
                                                <h4 className='mt-3 text-uppercase text-secondary'>Coming Soon</h4>
                                          </div>
                                          {/* <div className="tab-cnt">
                                                <form>
                                                      <div className="frm">
                                                            <textarea placeholder='Type post description here'></textarea>
                                                      </div>
                                                      <div className="frm">
                                                            <Select
                                                                  closeMenuOnSelect={false}
                                                                  components={animatedComponents}
                                                                  defaultValue={[options[0], options[1], options[2]]}
                                                                  isMulti
                                                                  options={options}
                                                            />
                                                      </div>
                                                </form>
                                          </div>
                                          <button className="send_btn">Post</button> */}
                                    </Tab>
                                    <Tab eventKey="Instagram" title="Instagram">
                                    <div className='csitxt fadein'>
                                                <img src={CreatePostimg} alt="" />
                                                <h4 className='mt-3 text-uppercase text-secondary'>Coming Soon</h4>
                                          </div>
                                          {/* <div className="tab-cnt">
                                                <form>
                                                      <div className="frm">
                                                            <textarea placeholder='Type post description here'></textarea>
                                                      </div>
                                                      <div className="frm">
                                                            <Select
                                                                  closeMenuOnSelect={false}
                                                                  components={animatedComponents}
                                                                  defaultValue={[options[0], options[1], options[2]]}
                                                                  isMulti
                                                                  options={options}
                                                            />
                                                      </div>
                                                </form>
                                          </div>
                                          <button className="send_btn">Post</button> */}
                                    </Tab>
                              </Tabs>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}


export default CreatePost;