import { React, useEffect, useRef, useState } from 'react';
import Logoblack from '../assets/images/logo_b.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { ButtonLoading } from './shared/Buttonloading';
import { ErrorMessage } from './shared/ErrorMessage';
import { logIn, resetLogIn } from '../actions/twitterAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

      const dispatch = useDispatch();
      const navigate = useNavigate();

      const [isActive, setActive] = useState(false);
      const LogInRes = useSelector(state => state.twitterReducer.LogIn);
      
      const handleLoginSubmit = async (event) => {
            event.preventDefault();
            setActive(true); 
            setTimeout(() => {
                  const email = event.target.elements.email.value;
                  const password = event.target.elements.password.value;
                  console.log(password, email);
                  if(password == ""){
                        console.log('fill all fields');
                        return;
                  }
                  let data = {
                        "email": email,
                        "password": password
                  }
                  dispatch(logIn(data));
            }, 1500);
      }

      useEffect(() => {
            console.log(LogInRes);
            if (LogInRes.statusCode == 200) {
                  localStorage.username = LogInRes.data.username;
                  localStorage.email = LogInRes.data.email;
                  localStorage.token = LogInRes.data.token;
                  dispatch(resetLogIn());
                  navigate("/dashboard");
            }else if(LogInRes.statusCode == 400){
                  toast.error(LogInRes.message, {autoClose:5000});
                  setActive(false);
            }
            else{
                  setActive(false);
            }
      }, [LogInRes]);

      return (
            <div className='login_page'>
                  <div className='container'>
                        <div className='flex'>
                              <div className='text'>
                                    <h1>Let’s get <span>start</span></h1>
                                    <p>Connect your accounts and safe private texting.</p>
                              </div>
                              <div className='log_form'>
                                    <div className="logo">
                                          <a href="#">
                                                <img src={Logoblack} alt="" />
                                          </a>
                                    </div>
                                    <form onSubmit={handleLoginSubmit}>
                                          <h3>LOGIN</h3>
                                          <div className="l_frm">
                                                <input type="email" name='email' id="email" placeholder='email' required  />
                                                <label htmlFor="email">Email Address</label>
                                          </div>
                                          <div className="l_frm">
                                                <input type="password" name="password" id="password" placeholder='password' required  />
                                                <label htmlFor="password">Password</label>
                                          </div>
                                          <div className="l_frm">
                                                <button type='submit' 
                                                      className={isActive ?  "btn_loading" : null }
                                                >
                                                <span> Login</span>
                                                      <ButtonLoading />
                                                </button>
                                                {/* <ErrorMessage /> */}
                                          </div>
                                    </form>
                              </div>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}

export default Login;