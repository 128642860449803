import { React, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

export const ComingSoonModal = (props) => {
      return (
            <Modal
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className='connet_modal fadein'
            >
                  <div className="bgs">
                        <Modal.Header closeButton>
                              <Modal.Title id="contained-modal-title-vcenter">
                                    Connect Profile
                              </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='flex-0'>
                              <div className='container'>
                                    <h2>Coming soon..!</h2>
                              </div>
                        </Modal.Body>
                  </div>
            </Modal>
      );
}