export const types = {
    CREATE_TWEET_SUCCESS : 'CREATE_TWEET_SUCCESS',
    CREATE_TWEET_FAILED : 'CREATE_TWEET_FAILED',
    GET_BOT_OPERATOR_SUCCESS : 'GET_BOT_OPERATOR_SUCCESS',
    GET_BOT_OPERATOR_FAILED : 'GET_BOT_OPERATOR_FAILED',
    GET_ACTIVE_BOT_OPERATOR_SUCCESS : 'GET_ACTIVE_BOT_OPERATOR_SUCCESS',
    GET_ACTIVE_BOT_OPERATOR_FAILED : 'GET_ACTIVE_BOT_OPERATOR_FAILED',
    GET_NEWSFEED_SUCCESS : 'GET_NEWSFEED_SUCCESS',
    GET_NEWSFEED_FAILED : 'GET_NEWSFEED_FAILED',
    GET_LOGIN_SUCCESS : 'GET_LOGIN_SUCCESS',
    GET_LOGIN_FAILED : 'GET_LOGIN_FAILED',
    CREATE_BOT_OPERATOR_SUCCESS : 'CREATE_BOT_OPERATOR_SUCCESS',
    CREATE_BOT_OPERATOR_FAILED : 'CREATE_BOT_OPERATOR_FAILED',
    UPDATE_BOT_OPERATOR_STATUS_SUCCESS: 'UPDATE_BOT_OPERATOR_STATUS_SUCCESS',
    UPDATE_BOT_OPERATOR_STATUS_FAILED: 'UPDATE_BOT_OPERATOR_STATUS_FAILED',
    GET_OPENAI_SUCCESS: 'GET_OPENAI_SUCCESS',
    GET_OPENAI_FAILED: 'GET_OPENAI_FAILED',
    REPLY_TWEET_SUCCESS : 'REPLY_TWEET_SUCCESS',
    REPLY_TWEET_FAILED : 'REPLY_TWEET_FAILED',
    GET_REPLY_TWEET_SUCCESS: 'GET_REPLY_TWEET_SUCCESS',
    GET_REPLY_TWEET_FAILED: 'GET_REPLY_TWEET_FAILED',
    CREATE_TARGET_ACCOUNT_SUCCESS: 'CREATE_TARGET_ACCOUNT_SUCCESS',
    CREATE_TARGET_ACCOUNT_FAILED: 'CREATE_TARGET_ACCOUNT_FAILED',
    GET_TARGET_ACCOUNT_SUCCESS: 'GET_TARGET_ACCOUNT_SUCCESS',
    GET_TARGET_ACCOUNT_FAILED: 'GET_TARGET_ACCOUNT_FAILED',
    UPDATE_TARGET_ACCOUNT_STATUS_SUCCESS: 'UPDATE_TARGET_ACCOUNT_STATUS_SUCCESS',
    UPDATE_TARGET_ACCOUNT_STATUS_FAILED: 'UPDATE_TARGET_ACCOUNT_STATUS_FAILED',
    CREATE_HASHTAG_SUCCESS: 'CREATE_HASHTAG_SUCCESS',
    CREATE_HASHTAG_FAILED: 'CREATE_HASHTAG_FAILED',
    GET_HASHTAG_SUCCESS: 'GET_HASHTAG_SUCCESS',
    GET_HASHTAG_FAILED: 'GET_HASHTAG_FAILED',
    UPDATE_HASHTAG_STATUS_SUCCESS: 'UPDATE_HASHTAG_STATUS_SUCCESS',
    UPDATE_HASHTAG_STATUS_FAILED: 'UPDATE_HASHTAG_STATUS_FAILED',
    DELETE_HASHTAG_SUCCESS: 'DELETE_HASHTAG_SUCCESS',
    DELETE_HASHTAG_FAILED: 'DELETE_HASHTAG_FAILED',
    DELETE_TARGET_ACCOUNT_SUCCESS: 'DELETE_TARGET_ACCOUNT_SUCCESS',
    DELETE_TARGET_ACCOUNT_FAILED: 'DELETE_TARGET_ACCOUNT_FAILED',
    GET_POST_HISTORY_SUCCESS: 'GET_POST_HISTORY_SUCCESS',
    GET_POST_HISTORY_FAILED: 'GET_POST_HISTORY_FAILED',
    GET_CRON_LOGS_SUCCESS: 'GET_CRON_LOGS_SUCCESS',
    GET_CRON_LOGS_FAILED: 'GET_CRON_LOGS_FAILED',
    GET_CRON_LOGS_COUNT_SUCCESS: 'GET_CRON_LOGS_COUNT_SUCCESS',
    GET_CRON_LOGS_COUNT_FAILED: 'GET_CRON_LOGS_COUNT_FAILED',
    GET_RECENT_NEWSFEED_SUCCESS: 'GET_RECENT_NEWSFEED_SUCCESS',
    GET_RECENT_NEWSFEED_FAILED: 'GET_RECENT_NEWSFEED_FAILED',
    DELETE_BOT_OPERATOR_SUCCESS: 'DELETE_BOT_OPERATOR_SUCCESS',
    DELETE_BOT_OPERATOR_FAILED: 'DELETE_BOT_OPERATOR_FAILED',
    GET_LAST_CRON_RUN_TIME_SUCCESS: 'GET_LAST_CRON_RUN_TIME_SUCCESS',
    GET_LAST_CRON_RUN_TIME_FAILED: 'GET_LAST_CRON_RUN_TIME_FAILED',
    UPDATE_BOT_OPERATOR_SUCCESS: 'UPDATE_BOT_OPERATOR_SUCCESS',
    UPDATE_BOT_OPERATOR_FAILED: 'UPDATE_BOT_OPERATOR_FAILED'
}