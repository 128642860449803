import { React, useEffect, useRef, useState } from 'react';
import Facebook_circle from '../assets/images/Facebook-circle.png';
import Instagram_circle from '../assets/images/insta-circle.png';
import Twitter_circle from '../assets/images/twitter-circle.png';
import User from '../assets/images/prof.png';
import Chatgpt from '../assets/images/chatgpt-icon.png';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Send from '../assets/images/send.png';
import HeaderProfileArea from './shared/HeaderProfileArea';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonLoading } from './shared/Buttonloading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRecentNewsFeed, resetGetRecentNewsFeed, getBotOperators, resetGetBotOperators, replyTweet, resetReplyTweet, getReplyTweets, resetGetReplyTweets, resetGetNewsFeedFailed, getLastCronRunTime, resetGetLastCronRunTime } from '../actions/twitterAction';
import { askOpenAi, resetAskOpenAi } from '../actions/openaiAction';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DClose from '../assets/images/dclose.png';
import ExtLink from '../assets/images/ext.png';
import Loader from './shared/Loader';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';
import Post_img1 from '../assets/images/post_img1.jpg';
import Post_img2 from '../assets/images/post_img2.jpg';
import Post_img3 from '../assets/images/post_img3.jpg';
import Post_img4 from '../assets/images/post_img4.jpg';

const RecentActivity = () => {

      dayjs.extend(relativeTime);

      const dispatch = useDispatch();
      const navigate = useNavigate();

      const createTweetRes = useSelector(state => state.twitterReducer.createTweet);

      const getRecentNewsFeedRes = useSelector(state => state.twitterReducer.getRecentNewsFeed);

      const openAIRes = useSelector(state => state.twitterReducer.getOpenAiPrompt);

      const openAIRes_rid = useSelector(state => state.twitterReducer.rid);

      const getBotOperatorRes = useSelector(state => state.twitterReducer.getBotOperator);

      const replyTweetRes = useSelector(state => state.twitterReducer.replyTweet);

      const replyTweetRes_rid = useSelector(state => state.twitterReducer.replyTweetRid);

      const getReplyTweetsRes = useSelector(state => state.twitterReducer.getReplyTweets);

      const getNewsfeedFailed = useSelector(state => state.twitterReducer.getAllNewsFeedFailed);

      const getLastCronRunTimeRes = useSelector(state => state.twitterReducer.getLastCronRunTime);

      const [recentActivities, setRecentActivities] = useState([]);

      const [showmsgbox, setShowmsgbox] = useState([]);

      const [twitterBotList, setTwitterBotList] = useState([]);

      const [twitterBotOperatorDetails, setTwitterBotOperatorDetails] = useState([]);

      const [botselectors, setBotselectors] = useState([]);

      const [replyTweetList, setReplyTweetList] = useState({});

      const [replyText, setReplyText] = useState([]);

      const [loadingBtnOpenAi, setLoadingBtnOpenAi] = useState([]);

      const [loadingBtnReply, setLoadingBtnReply] = useState([]);

      const [loadingNewsfeed, setLoadingNewsfeed] = useState(true);

      const [lastCronRunTime, setLastCronRunTime] = useState('');



      useEffect(() => {
            dispatch(getBotOperators());
            dispatch(getRecentNewsFeed());
            dispatch(getLastCronRunTime());
            setInterval(()=>{
                  dispatch(getLastCronRunTime());
            },60000);
            
      }, []);

      useEffect(() => {
            if (getLastCronRunTimeRes.statusCode == 200) {
                  
                  setLastCronRunTime(getLastCronRunTimeRes.data.lastRunTime);

                  dispatch(resetGetLastCronRunTime());
            }
      }, [getLastCronRunTimeRes]);

      useEffect(() => {
            if (getBotOperatorRes.statusCode == 200) {
                  let botlist = getBotOperatorRes.data.users;
                  const newArray = botlist.filter((item) => {
                        if(item.accountStatus == 1){
                              return true;
                        }
                        return false;
                  }).map((item) => {
                        return { value: `${item.id}`, label: `${item.accountName}` };
                  });
                  setTwitterBotList(newArray);

                  setTwitterBotOperatorDetails(botlist);
            }
      }, [getBotOperatorRes]);

      useEffect(() => {
            console.log(getNewsfeedFailed);
            if(getNewsfeedFailed.statusCode == 401){
                  dispatch(resetGetNewsFeedFailed());
                  toast.error(getNewsfeedFailed.message, {autoClose:6000,onClose: () => {
                        dispatch(emptyRedux());
                        logout();
                        navigate("/login");
                  }});
            }
      }, [getNewsfeedFailed]);
      
      useEffect(() => {

            if (
                  getRecentNewsFeedRes.hasOwnProperty("data") &&
                  getRecentNewsFeedRes.data.hasOwnProperty("newsfeed") &&
                  typeof getRecentNewsFeedRes.data.newsfeed != "undefined"
            ) {
                  if(getRecentNewsFeedRes.data.newsfeed.length > 0){

                        let initialData = [], botList_operators = [], replyTextInitData = [];

                        for (var i = 0; i < getRecentNewsFeedRes.data.count; i++) {
                              initialData.push(false);
                              replyTextInitData.push('');
                              botList_operators.push([]);
                              if(typeof getRecentNewsFeedRes.data.newsfeed[i].mediaFiles != "undefined"){
                                    getRecentNewsFeedRes.data.newsfeed[i].mediaFiles = JSON.parse(getRecentNewsFeedRes.data.newsfeed[i].mediaFiles);
                              }
                              else{
                                    getRecentNewsFeedRes.data.newsfeed[i].mediaFiles = [];
                              }
                        }

                        setShowmsgbox([
                              ...initialData
                        ]);
                        setLoadingBtnOpenAi([
                              ...initialData
                        ]);
                        setLoadingBtnReply([
                              ...initialData
                        ]);
                        setBotselectors([
                              ...botList_operators
                        ]);
                        setReplyText([
                              ...replyTextInitData
                        ]);

                        setRecentActivities(
                              getRecentNewsFeedRes.data.newsfeed
                        );
                  }
                  setLoadingNewsfeed(false);
                  dispatch(resetGetRecentNewsFeed());

            }

      }, [getRecentNewsFeedRes]);

      useEffect(() => {        

            if (typeof getReplyTweetsRes != "undefined" && getReplyTweetsRes.statusCode == 200) {

                  if (getReplyTweetsRes.data.count > 0) {

                        let recentActivitiesAry = recentActivities;

                        let replyActivities = getReplyTweetsRes.data.activities;
                        
                        for (let i in replyActivities) {

                              //Find the array index for newsfeed payload
                              let recentActi_index = -1;
                              for (let j in recentActivitiesAry) {
                                    if(recentActivitiesAry[j].newsfeedId == replyActivities[i].newsfeedId){
                                          recentActi_index = j; break;
                                    }
                              }

                              //To add the reply tweet data to the matched newsfeed item
                              if(recentActivitiesAry[recentActi_index].hasOwnProperty('replyTweets') === false ){
                                    recentActivitiesAry[recentActi_index].replyTweets = [];
                              }
                              recentActivitiesAry[recentActi_index].replyTweets.push(replyActivities[i]);
                        }

                        setRecentActivities(recentActivitiesAry);
                  }

                  dispatch(resetGetReplyTweets());
            }

      }, [getReplyTweetsRes]);

      useEffect(() => {

            if (typeof openAIRes == "string" && openAIRes != "" && typeof openAIRes_rid != "undifined") {

                  let get_msg_box = showmsgbox;
                  get_msg_box[openAIRes_rid] = get_msg_box[openAIRes_rid] == true ? false : true;
                  setShowmsgbox([
                        ...get_msg_box
                  ]);

                  let replyTextAry = replyText;

                  replyTextAry[openAIRes_rid] = openAIRes.indexOf("\n") === 0 ? openAIRes.slice(1) : openAIRes;
                  setReplyText([
                        ...replyTextAry
                  ]);
                  dispatch(resetAskOpenAi());
            }

      }, [openAIRes]);

      useEffect(() => {

            if (replyTweetRes.statusCode == 200) {
                  setTimeout(() => {
                        dispatch(resetReplyTweet());
                        // setFetched(true);
                  }, 2500);

                  let replyTweet_data = {
                        newsfeedId: recentActivities[replyTweetRes_rid].newsfeedId
                  };

                  dispatch(getReplyTweets(replyTweet_data));

                  toast.success('Successfully posted your reply tweet..!', { autoClose: 3000 });
            }

            let get_msg_box;
            get_msg_box = showmsgbox.map((vlu) => {
                  return false;
            });
            setShowmsgbox([
                  ...get_msg_box
            ]);

            setLoadingBtnOpenAi([
                  ...get_msg_box
            ]);

      }, [replyTweetRes]);

      const handleReplyTextChange = (event, key) => {

            let replyTextAry = replyText;
            replyTextAry[key] = event.target.value;
            setReplyText([
                  ...replyTextAry
            ]);
      }

      const callOpenAi = (newfeedId, msgboxKey) => {

            let newfeedData = recentActivities;

            let prompt_data = {
                  "prompt": "Please suggest the positive reply for this content: " + newfeedData[msgboxKey].newsfeedContent
            };

            dispatch(askOpenAi(prompt_data, msgboxKey));

            let loadingBtnOpenAiAry = loadingBtnOpenAi;

            loadingBtnOpenAiAry[msgboxKey] = true;

            setLoadingBtnOpenAi([
                  ...loadingBtnOpenAiAry
            ]);
      }

      const handleBotOperatorChanges = (key, options) => {

            let bot_ids = [];

            for (var i = 0, l = options.length; i < l; i++) {
                  bot_ids.push(options[i].value);
            }

            let botselectorsAry = botselectors;
            botselectorsAry[key] = bot_ids;
            setBotselectors([
                  ...botselectorsAry
            ]);
      }

      const submitReply = (newfeedId, msgboxKey) => {

            if (replyText[msgboxKey].trim() != "") {
                  if(botselectors[msgboxKey].length > 0){
                        botselectors[msgboxKey].map((item) => {
                              let reply_data = {
                                    "reply_tweet": replyText[msgboxKey],
                                    "tweet_id": newfeedId,
                                    "bot_id": item
                              }

                              dispatch(replyTweet(reply_data, msgboxKey));
                        });

                        let replyTextAry = replyText;
                        replyTextAry[msgboxKey] = "";
                        setReplyText([
                              ...replyTextAry
                        ]);

                        let loadingBtnReplyAry = loadingBtnReply;

                        loadingBtnReplyAry[msgboxKey] = true;

                        setLoadingBtnReply([
                              ...loadingBtnReplyAry
                        ]);

                  }
                  else{
                        toast.error('Please select the users..!', { autoClose: 3000 });
                  }

            }
            else{
                  toast.error('invalid or empty value given. please try again!', { autoClose: 3000 });
            }
      }

      const animatedComponents = makeAnimated();

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft ptflex">
                                    <h3>Live Feeds </h3>  
                                    {lastCronRunTime ?
                                          <span className='small text-white'>[Last sync {dayjs(lastCronRunTime).fromNow()}]</span> 
                                          :""
                                    }
                              </div>
                              <HeaderProfileArea />
                        </div>
                  </header>
                  <div className="main_content innermain">
                        <div className="rec_box recentactivity fadein">
                              <div>
                              {
                                    loadingNewsfeed === true 
                              ? 
                              
                                    <Loader></Loader> 

                              :
                                    (     
                                          recentActivities.length === 0 
                                    ? 
                                          <p className="text-center">No posts available in the past week</p>
                                    :
                                          recentActivities.map((value, key) => {

                                                return (
                                                      <div key={key} className={"boxs " + (value.socialmediaType == "twitter" ? "_twt" : (value.socialmediaType == "facebook" ? "_fb" : "_insta"))} >
                                                            {/* <div className='d-close'><img src={DClose} alt="" /></div> */}
                                                            <div className='d-close extlinks'>
                                                                  <a href={'https://twitter.com/'+value.userName+'/status/'+value.newsfeedId} target='_blank'>
                                                                  <img src={ExtLink} alt="" />
                                                                  </a>
                                                            </div>
                                                            <div className="meida_icons">
                                                                  <img src={value.socialmediaType == "twitter" ? Twitter_circle : (value.socialmediaType == "facebook" ? Facebook_circle : Instagram_circle)} alt="" />
                                                            </div>
                                                            <div className="media_txt">
                                                                  <div className="icon">
                                                                        <img src={User} alt="" />
                                                                  </div>
                                                                  <div className="_txt">
                                                                        <h4>{value.userName}</h4>
                                                                        <span>{dayjs(value.createdAt).fromNow()}</span>
                                                                        <pre className='textoverflowfix'>
                                                                              <p>{value.newsfeedContent}</p>

                                                                              <div className={'post_img' + (value.mediaFiles.length === 0 ? 'd-none' : '') }>
                                                                                    <ul>
                                                                                          {value.mediaFiles.length > 0 ? value.mediaFiles.map((file_data, file_key) => {

                                                                                                let media_key = file_data.media_key;

                                                                                                let media_url = "";

                                                                                                if(file_data.type === "photo"){
                                                                                                      media_url = file_data.url;
                                                                                                }
                                                                                                else if(file_data.type === "video"){
                                                                                                      
                                                                                                      for(let vi in file_data.variants){
                                                                                                            if(file_data.variants[vi].content_type === "video/mp4" && file_data.variants[vi].bit_rate === 2176000){
                                                                                                                  media_url = file_data.variants[vi].url;  break;
                                                                                                            }
                                                                                                      }
                                                                                                }
                                                                                                else if(file_data.type === "animated_gif" ){
                                                                                                      media_url = file_data.variants[0].url;
                                                                                                }
                                                                                                
                                                                                                return (
                                                                                                      <li>
                                                                                                            <a href={media_url} target="_blank">
                                                                                                                  {media_url != "" ? (     
                                                                                                                        file_data.type === "video" || file_data.type === "animated_gif"
                                                                                                                  ? 
                                                                                                                        <video controls >
                                                                                                                              <source src={media_url} type="video/mp4" />
                                                                                                                        </video>
                                                                                                                  :
                                                                                                                        <img src={media_url} alt={media_key} />
                                                                                                                  )
                                                                                                                  : null
                                                                                                                  }
                                                                                                            </a>
                                                                                                      </li>
                                                                                                );
                                                                                          }) 
                                                                                          : ""
                                                                                          }
                                                                                          
                                                                                    </ul>
                                                                              </div>


                                                                        </pre>
                                                                        {/*<ul>
                                                                        <li>#Lorem</li>
                                                                        <li>@ipsum</li>
                                                                        </ul>*/}
            
                                                                        {typeof recentActivities[key].replyTweets != "undefined" ? recentActivities[key].replyTweets.map((r_twt, rt_key) => {
                                                                              return (
                                                                              <div key={rt_key+1} className="msg_box " >
                                                                                    <div className="media_txt mb-2 ms-0 bg-white w-100">
                                                                                          <div className="_txt retwit">
                                                                                                <div className='retwituser'>
                                                                                                      <h4>{r_twt.botUsername}</h4>
                                                                                                      <span>{dayjs(r_twt.createdAt).fromNow()}</span>
                                                                                                </div>
                                                                                                <div className="msg_box" >
                                                                                                      <p className='m-0'>{r_twt.replyContent}</p>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
            
                                                                              </div>
                                                                              )
                                                                        }) : null}
            
                                                                        <button type='button' className={'gpt_btn ' + (showmsgbox[key] ? "d-none " : "") + (loadingBtnOpenAi[key] ? "btn_loading" : "")} onClick={() => callOpenAi(value.id, key)}>
                                                                              <img src={Chatgpt} alt="" />
                                                                              <span> Generate response from chatgpt</span>
                                                                              <ButtonLoading />
                                                                        </button>
                                                                  </div>
            
                                                                  <div className={"msg_box " + (showmsgbox[key] ? "" : "d-none")} >
                                                                        <div className="show_msg">
                                                                              <textarea
                                                                              id={"replyText"+key}
                                                                              name={"replyText"+key}
                                                                              value={replyText[key]}
                                                                              onChange={(e) => handleReplyTextChange(e, key)}
                                                                              className="form-control replytextarea"
                                                                              />
                                                                        </div>
                                                                        <div className="type_box">
                                                                              <Select
                                                                                    closeMenuOnSelect={false}
                                                                                    components={animatedComponents}
                                                                                    isMulti
                                                                                    options={twitterBotList}
                                                                                    onChange={(e) => handleBotOperatorChanges(key, e)}
                                                                              />
                                                                              <button className={'send_btn ' + (loadingBtnReply[key] ? "btn_loading" : "")} type='button' onClick={() => submitReply(value.newsfeedId, key)} >
                                                                                    <span> <img src={Send} alt="" /></span>
                                                                                    <ButtonLoading />
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                );
                                          })
                                    )
                              }
                              </div>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}


export default RecentActivity;