import { React, useEffect, useRef, useState } from 'react';
import DashIcon1 from '../assets/images/smartphone.png';
import DashIcon2 from '../assets/images/p_today.png';
import DashIcon3 from '../assets/images/Bot-Operator.png';
import DashIcon4 from '../assets/images/log.png';
import Table from 'react-bootstrap/Table';
import Dot from '../assets/images/dot.png';
import Facebook from '../assets/images/facebook.png';
import Instagram from '../assets/images/instagram.png';
import Twitter from '../assets/images/twitter.png';
import HeaderProfileArea from './shared/HeaderProfileArea';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentNewsFeed, resetGetRecentNewsFeed, getBotOperators, resetGetBotOperators, getAllPostHistory, resetGetAllPostHistory, getCronLogsCount, resetGetCronLogsCount, getHashtag, resetGetHashtag, getTargetAccounts, resetGetTargetAccounts, resetGetTargetAccountsFailed } from '../actions/twitterAction';
import dayjs from "dayjs";
import Loader from './shared/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';

const Dashboard = () => {

      const dispatch = useDispatch();
      const navigate = useNavigate();

      const getRecentNewsFeedRes = useSelector(state => state.twitterReducer.getRecentNewsFeed);

      const getBotOperatorRes = useSelector(state => state.twitterReducer.getBotOperator);

      const getAllPostHistoryRes = useSelector(state => state.twitterReducer.getAllPostHistory);

      const getCronLogsCountRes = useSelector(state => state.twitterReducer.getCronLogsCount);

      const getTargetAccountsRes = useSelector(state => state.twitterReducer.getTargetAccounts);

      const getTargetAccountsFailed = useSelector(state => state.twitterReducer.getTargetAccountsFailed);

      const getHashtagRes = useSelector(state => state.twitterReducer.getHashtag);

      const [recentActivityCount, setRecentActivityCount] = useState('');

      const [botOperatorCount, setBotOperatorCount] = useState('');

      const [postHistoryCount, setPostHistoryCount] = useState('');

      const [cronLogsCount, setCronLogsCount] = useState('');

      const [postHistoryList, setPostHistoryList] = useState('');

      const [targetAccountList, setTargetAccountList] = useState([]);

      const [hashtagList, setHashtagList] = useState([]);

      const [postHistoryLoading, setPostHistoryLoading] = useState(true);
      
      useEffect(() => {
            dispatch(getBotOperators());
            dispatch(getRecentNewsFeed());
            dispatch(getAllPostHistory());
            dispatch(getCronLogsCount());
            dispatch(getTargetAccounts());
            dispatch(getHashtag());
      }, []);
      
      useEffect(() => {

            if (
                  getRecentNewsFeedRes.hasOwnProperty("data") &&
                  getRecentNewsFeedRes.data.hasOwnProperty("newsfeed") &&
                  typeof getRecentNewsFeedRes.data.newsfeed != "undefined"
            ) {
                  setRecentActivityCount(getRecentNewsFeedRes.data.count);
                  dispatch(resetGetRecentNewsFeed());
            }

      }, [getRecentNewsFeedRes]);

      useEffect(() => {
            if (getBotOperatorRes.statusCode == 200) {
                  
                  setBotOperatorCount(getBotOperatorRes.data.count);
                  //dispatch(resetGetBotOperators());
            }
      }, [getBotOperatorRes]);

      useEffect(() => {
            if(getTargetAccountsFailed.statusCode == 401){
                  dispatch(resetGetTargetAccountsFailed());
                  toast.error(getTargetAccountsFailed.message, {autoClose:6000,onClose: () => {
                        dispatch(emptyRedux());
                        logout();
                        navigate("/login");
                  }});
            }
      }, [getTargetAccountsFailed]);

      useEffect(() => {
            if (getAllPostHistoryRes.statusCode == 200) {
                  
                  let posthistory = getAllPostHistoryRes.data.history;
                  setPostHistoryCount(posthistory.length);
                  setTimeout(()=>{
                        setPostHistoryLoading(false);
                        setPostHistoryList(posthistory);
                        dispatch(resetGetAllPostHistory());
                  },1500);
                  
            }
      }, [getAllPostHistoryRes]);

      useEffect(() => {
            if (getCronLogsCountRes.statusCode == 200) {
                  setCronLogsCount(getCronLogsCountRes.data.count);
                  dispatch(resetGetCronLogsCount());
            }
      }, [getCronLogsCountRes]);

      useEffect(() => {
            if (getTargetAccountsRes.statusCode == 200) {

                  let targetAccountAry = getTargetAccountsRes.data.accounts.filter((account, indx) => {
                        if(account.accountStatus == 1) {
                              return true;
                        }
                        return false;
                  });

                  setTargetAccountList([
                        ...targetAccountAry
                  ]);
                  dispatch(resetGetTargetAccounts());
            }
      }, [getTargetAccountsRes]);


      useEffect(() => {
            if (getHashtagRes.statusCode == 200) {
                  
                  let hashtagAry = getHashtagRes.data.hashtags.filter((hshtg, indx) => {
                        if(hshtg.hashtagStatus == 1) {
                              return true;
                        }
                        return false;
                  });

                  setHashtagList([
                        ...hashtagAry
                  ]);
                  dispatch(resetGetHashtag());
            }
      }, [getHashtagRes]);

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft">
                                    <h4>Hey George</h4>
                                    <p>Here’s a summary of your posts across all your social accounts.</p>
                              </div>
                              <HeaderProfileArea />
                        </div>
                  </header>

                  <div className="main_content">
                        <div className="dash_box">

                              <div className="row">
                                    <div className="col-md-3">
                                          <div className="box fadein">
                                                <div className="txt">
                                                      <p>Live Feeds</p>
                                                      <h2>{recentActivityCount}</h2>
                                                </div>
                                                <div className="img">
                                                      <img src={DashIcon1} alt="" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="col-md-3">
                                          <div className="box fadein">
                                                <div className="txt">
                                                      <p>Post Published</p>
                                                      <h2>{postHistoryCount}</h2>
                                                </div>
                                                <div className="img">
                                                      <img src={DashIcon2} alt="" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="col-md-3">
                                          <div className="box fadein">
                                                <div className="txt">
                                                      <p>Bot Operator</p>
                                                      <h2>{botOperatorCount}</h2>
                                                </div>
                                                <div className="img">
                                                      <img src={DashIcon3} alt="" />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="col-md-3">
                                          <div className="box fadein">
                                                <div className="txt">
                                                      <p>Logs</p>
                                                      <h2>{cronLogsCount}</h2>
                                                </div>
                                                <div className="img">
                                                      <img src={DashIcon4} alt="" />
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className="row">
                              <div className="col-md-9">
                                    <div className="post_history mt-30px fadein">
                                          <h4>Posting History</h4>
                                          <Table responsive>
                                                <thead>
                                                      <tr>
                                                            <th>Account Name</th>
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                            <th>Content</th>
                                                            {/* <th>Error</th> */}
                                                            {/* <th>Action</th> */}
                                                      </tr>
                                                </thead>
                                                {postHistoryLoading == true ?
                                                      <div className="posthistoryloader">
                                                            <Loader/>
                                                      </div>
                                                      :''
                                                }
                                                <tbody>
                                                      {postHistoryList.length > 0 ? postHistoryList.map((value, key) => {
                                                            return (
                                                                  <tr key={key}>
                                                                        <td>
                                                                              <div className="media_name">
                                                                                    <i>
                                                                                          <img src={value.socialmediaType == "twitter" ? Twitter : (value.socialmediaType == "facebook" ? Facebook : Instagram )} alt="" />
                                                                                    </i>
                                                                                    <span>{value.botUsername}</span>
                                                                              </div>
                                                                        </td>
                                                                        <td>{dayjs(new Date(value.createdAt)).format('DD-MMM-YYYY')}</td>
                                                                        <td>{dayjs(new Date(value.createdAt)).format('hh:mm A')}</td>
                                                                        <td className='truncate-table-cell'>{value.content}</td>
                                                                        {/* <td>
                                                                              <a href="#">
                                                                                    <img src={Dot} alt="" />
                                                                              </a>
                                                                        </td> */}
                                                                  </tr>
                                                            );
                                                      }) 
                                                      : ""
                                                      }
                                                      {postHistoryList.length == 0 && postHistoryLoading != true ?
                                                            <tr>
                                                                  <td colSpan="4" className="text-center">
                                                                  No social media posts have been published.
                                                                  </td>
                                                            </tr>
                                                            : ""
                                                      }

                                                </tbody>
                                          </Table>
                                    </div>
                              </div>
                              <div className="col-md-3">
                                    <div className="post_history mt-30px">
                                    <h4>&nbsp;</h4>
                                    <div className="dash_box targettxts">
                                   
                                          <div className="box mb-3 fadein">
                                                <div className="txt">
                                                      <h4>Twitter tracking status</h4>
                                                      <p><span className='onlinetxt'></span> Online</p>
                                                      
                                                </div>                                               
                                          </div>

                                          <div className="box mb-3 fadein">
                                                <div className="txt">
                                                      <h4>Targeted Twitter account</h4>
                                                      
                                                      {targetAccountList.length > 0 ? targetAccountList.map((value, key) => {
                                                           return ( <p key={key}>{value.accountName}</p> );
                                                      }) : 
                                                            
                                                            <p>-</p>
                                                            
                                                      }
                                                </div>
                                               
                                          </div>

                                          <div className="box mb-3 fadein">
                                                <div className="txt">
                                                      <h4>Targeted Twitter hashtag</h4>
                                                      {hashtagList.length > 0 ? hashtagList.map((value, key) => {
                                                            return ( <p key={key}>{value.hashtagName}</p> );
                                                      }) : 

                                                            <p>-</p>
                                                      }
                                                </div>
                                               
                                          </div>
                                    </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}


export default Dashboard;