import { types } from "../constants/types";

const initialState = {
    createTweet : [],
    createTweetFailed : '',
    getBotOperator : [],
    getBotOperatorFailed : '',
    getActiveBotOperator : [],
    getActiveBotOperatorFailed : '',
    getAllNewsFeed : [],
    getAllNewsFeedFailed : '',
    LogIn : [],
    LogInFailed : '',
    createBotOperator : [],
    createBotOperatorFailed : '',
    updateBotOperator : [],
    updateBotOperatorFailed : '',
    updateBotOperatorStatus : [],
    updateBotOperatorStatusFailed : '',
    getOpenAiPrompt: [],
    getOpenAiPromptFailed: '',
    replyTweet : [],
    replyTweetFailed : '',
    replyTweetRid : '',
    replyTweetRidFailed : '',
    getReplyTweets: [],
    getReplyTweetsFailed: '',
    createTargetAccount: [],
    createTargetAccountFailed: '',
    getTargetAccounts: [],
    getTargetAccountsFailed: '',
    createHashtag: [],
    createHashtagFailed: '',
    getHashtag: [],
    getHashtagFailed: '',
    updateHashtagStatus: [],
    updateHashtagStatusFailed: '',
    deleteHashtag: [],
    deleteHashtagFailed: '',
    deleteTargetAccount: [],
    deleteTargetAccountFailed: '',
    getAllPostHistory : [],
    getAllPostHistoryFailed : '',
    getAllCronLogs: [],
    getAllCronLogsFailed: '',
    getCronLogsCount: [],
    getCronLogsCountFailed: '',
    getRecentNewsFeed: [],
    getRecentNewsFeedFailed: '',
    deleteBotOperator: [],
    deleteBotOperatorFailed: '',
    getLastCronRunTime: [],
    getLastCronRunTimeFailed: ''

}
//receives the current state and an action object
export function twitterReducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_TWEET_SUCCESS:
            return {
                ...state,
                createTweet : action.payload
            }
        case types.CREATE_TWEET_FAILED:
            return {
                ...state,
                createTweetFailed : action.payload
            }
        case types.GET_BOT_OPERATOR_SUCCESS:
            return {
                ...state,
                getBotOperator : action.payload
            }
        case types.GET_BOT_OPERATOR_FAILED:
            return {
                ...state,
                getBotOperatorFailed : action.payload
            }
        case types.GET_ACTIVE_BOT_OPERATOR_SUCCESS:
            return {
                ...state,
                getActiveBotOperator : action.payload
            }
        case types.GET_ACTIVE_BOT_OPERATOR_FAILED:
            return {
                ...state,
                getActiveBotOperatorFailed : action.payload
            }    
        case types.GET_NEWSFEED_SUCCESS:
            return {
                ...state,
                getAllNewsFeed : action.payload
            }
        case types.GET_NEWSFEED_FAILED:
            return {
                ...state,
                getAllNewsFeedFailed : action.payload
            }
        case types.GET_LOGIN_SUCCESS:
            return {
                ...state,
                LogIn : action.payload
            }
        case types.GET_LOGIN_FAILED:
            return {
                ...state,
                LogInFailed : action.payload
            }
        case types.CREATE_BOT_OPERATOR_SUCCESS:
            return {
                ...state,
                createBotOperator : action.payload
            }
        case types.CREATE_BOT_OPERATOR_FAILED:
            return {
                ...state,
                createBotOperatorFailed : action.payload
            }
        case types.UPDATE_BOT_OPERATOR_SUCCESS:
            return {
                ...state,
                updateBotOperator : action.payload
            }
        case types.UPDATE_BOT_OPERATOR_FAILED:
            return {
                ...state,
                updateBotOperatorFailed : action.payload
            }
        case types.UPDATE_BOT_OPERATOR_STATUS_SUCCESS:
            return {
                ...state,
                updateBotOperatorStatus : action.payload
            }
        case types.UPDATE_BOT_OPERATOR_STATUS_FAILED:
            return {
                ...state,
                updateBotOperatorStatusFailed : action.payload
            }
        case types.GET_OPENAI_SUCCESS:
            return {
                ...state,
                getOpenAiPrompt : action.payload,
                rid : action.rid
            }
        case types.GET_OPENAI_FAILED:
            return {
                ...state,
                getOpenAiPromptFailed : action.payload,
                rid : action.rid
            }
        case types.REPLY_TWEET_SUCCESS:
            return {
                ...state,
                replyTweet : action.payload,
                replyTweetRid : action.rid
            }
        case types.REPLY_TWEET_FAILED:
            return {
                ...state,
                replyTweetFailed : action.payload,
                replyTweetRidFailed : action.rid
            }
        case types.GET_REPLY_TWEET_SUCCESS:
            return {
                ...state,
                getReplyTweets : action.payload
            }
        case types.GET_REPLY_TWEET_FAILED:
            return {
                ...state,
                getReplyTweetsFailed : action.payload
            }
        case types.CREATE_TARGET_ACCOUNT_SUCCESS:
            return {
                ...state,
                createTargetAccount : action.payload
            }
        case types.CREATE_TARGET_ACCOUNT_FAILED:
            return {
                ...state,
                createTargetAccountFailed : action.payload
            }
        case types.GET_TARGET_ACCOUNT_SUCCESS:
            return {
                ...state,
                getTargetAccounts : action.payload
            }
        case types.GET_TARGET_ACCOUNT_FAILED:
            return {
                ...state,
                getTargetAccountsFailed : action.payload
            }
        case types.UPDATE_TARGET_ACCOUNT_STATUS_SUCCESS:
            return {
                ...state,
                updateTargetAccountStatus : action.payload
            }
        case types.UPDATE_TARGET_ACCOUNT_STATUS_FAILED:
            return {
                ...state,
                updateTargetAccountStatusFailed : action.payload
            }
        case types.CREATE_HASHTAG_SUCCESS:
            return {
                ...state,
                createHashtag : action.payload
            }
        case types.CREATE_HASHTAG_FAILED:
            return {
                ...state,
                createHashtagFailed : action.payload
            }
        case types.GET_HASHTAG_SUCCESS:
            return {
                ...state,
                getHashtag : action.payload
            }
        case types.GET_HASHTAG_FAILED:
            return {
                ...state,
                getHashtagFailed : action.payload
            }
        case types.UPDATE_HASHTAG_STATUS_SUCCESS:
            return {
                ...state,
                updateHashtagStatus : action.payload
            }
        case types.UPDATE_HASHTAG_STATUS_FAILED:
            return {
                ...state,
                updateHashtagStatusFailed : action.payload
            }
        case types.DELETE_HASHTAG_SUCCESS:
            return {
                ...state,
                deleteHashtag : action.payload
            }
        case types.DELETE_HASHTAG_FAILED:
            return {
                ...state,
                deleteHashtagFailed : action.payload
            }
        case types.DELETE_TARGET_ACCOUNT_SUCCESS:
            return {
                ...state,
                deleteTargetAccount : action.payload
            }
        case types.DELETE_TARGET_ACCOUNT_FAILED:
            return {
                ...state,
                deleteTargetAccountFailed : action.payload
            }
        case types.GET_POST_HISTORY_SUCCESS:
            return {
                ...state,
                getAllPostHistory : action.payload
            }
        case types.GET_POST_HISTORY_FAILED:
            return {
                ...state,
                getAllPostHistoryFailed : action.payload
            }
        case types.GET_CRON_LOGS_SUCCESS:
            return {
                ...state,
                getAllCronLogs : action.payload
            }
        case types.GET_CRON_LOGS_FAILED:
            return {
                ...state,
                getAllCronLogsFailed : action.payload
            }
        case types.GET_CRON_LOGS_COUNT_SUCCESS:
            return {
                ...state,
                getCronLogsCount : action.payload
            }
        case types.GET_CRON_LOGS_COUNT_FAILED:
            return {
                ...state,
                getCronLogsCountFailed : action.payload
            }
        case types.GET_RECENT_NEWSFEED_SUCCESS:
            return {
                ...state,
                getRecentNewsFeed : action.payload
            }
        case types.GET_RECENT_NEWSFEED_FAILED:
            return {
                ...state,
                getRecentNewsFeedFailed : action.payload
            }
        case types.DELETE_BOT_OPERATOR_SUCCESS:
            return {
                ...state,
                deleteBotOperator : action.payload
            }
        case types.DELETE_BOT_OPERATOR_FAILED:
            return {
                ...state,
                deleteBotOperatorFailed : action.payload
            }
        case types.GET_LAST_CRON_RUN_TIME_SUCCESS:
            return {
                ...state,
                getLastCronRunTime : action.payload
            }
        case types.GET_LAST_CRON_RUN_TIME_FAILED:
            return {
                ...state,
                getLastCronRunTimeFailed : action.payload
            }
        
        default:
            return state
    }
}