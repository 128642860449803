import { React, useEffect, useRef, useState } from 'react';
import Error from '../assets/images/error_page.jpg';


const PageNotFound = () => {
      return (
            <div className="error">
                 <div className="img">
                        <img src={Error} alt="" />
                 </div>
                 <div className="txt">
                        <a href="/dashboard">Go to Home</a>
                 </div>
            </div>
      )
}


export default PageNotFound;