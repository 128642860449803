import { React} from 'react';

const Loader = () => {
      return(
            <div className='loading'>
                  <span className="loader"></span>
            </div>
      );
}

export default Loader;