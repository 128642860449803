import { React, useEffect, useRef, useState } from 'react';
import Profile from '../assets/images/Profile.png';
import Round_twt from '../assets/images/twitter-circle.png';
import Round_fb from '../assets/images/Facebook-circle.png';
import Round_insta from '../assets/images/insta-circle.png';
import Table from 'react-bootstrap/Table';
import Delete from '../assets/images/delete.png';
import HeaderProfileArea from './shared/HeaderProfileArea';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonLoading } from './shared/Buttonloading';
import { useDispatch, useSelector } from 'react-redux';
import { getBotOperators, resetGetBotOperators, createTargetAccount, resetCreateTargetAccount, getTargetAccounts, resetGetTargetAccounts, updateTargetAccountStatus, resetUpdateTargetAccountStatus, getHashtag, resetGetHashtag, createHashtag, resetCreateHashtag, updateHashtagStatus, resetUpdateHashtagStatus, deleteHashtag, resetDeleteHashtag, deleteTargetAccount, resetDeleteTargetAccount, resetGetTargetAccountsFailed, resetCreateTargetAccountFailed } from '../actions/twitterAction';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';
import Loader from './shared/Loader';

const SocialMediaSettings = () => {

      const dispatch = useDispatch();
      const navigate = useNavigate();
      
      const [modalShow, setModalShow] = useState(false);
      
      // const getBotOperatorRes = useSelector(state => state.twitterReducer.getBotOperator);

      const getTargetAccountsRes = useSelector(state => state.twitterReducer.getTargetAccounts);

      const getTargetAccountsFailed = useSelector(state => state.twitterReducer.getTargetAccountsFailed);

      const createTargetAccountRes = useSelector(state => state.twitterReducer.createTargetAccount);

      const createTargetAccountFailed = useSelector(state => state.twitterReducer.createTargetAccountFailed);

      const updateTargetAccountStatusRes = useSelector(state => state.twitterReducer.updateTargetAccountStatus);

      const getHashtagRes = useSelector(state => state.twitterReducer.getHashtag);

      const createHashtagRes = useSelector(state => state.twitterReducer.createHashtag);

      const updateHashtagStatusRes = useSelector(state => state.twitterReducer.updateHashtagStatus);

      const deleteTargetAccountRes = useSelector(state => state.twitterReducer.deleteTargetAccount);

      const deleteHashtagRes = useSelector(state => state.twitterReducer.deleteHashtag);

      // const [twitterBotList, setTwitterBotList] = useState([]);

      const [targetAccountList, setTargetAccountList] = useState([]);

      const [hashtagList, setHashtagList] = useState([]);

      const [usernameInput, setUsernameInput] = useState('');

      const [hashtagInput, setHashtagInput] = useState('');

      const [loadingBtn, setLoadingBtn] = useState({"targetaccount" : false, "hashtag": false});

      const [removeDataType, setRemoveDataType] = useState('');

      const [removeDataId, setRemoveDataId] = useState(0);

      const [loadingTwtUsers, setLoadingTwtUsers] = useState(true);

      const [loadingTwtHashTag, setLoadingTwtHashTag] = useState(true);

      useEffect(() => {
            // dispatch(getBotOperators());
            dispatch(getTargetAccounts());
            dispatch(getHashtag());
      },[]);

      // useEffect(() => {
      //       if (getBotOperatorRes.statusCode == 200) {
      //             console.log(getBotOperatorRes.data.users);
      //             let botlist = getBotOperatorRes.data.users;
      //             const newArray = botlist.map((item) => {
      //                   return { value: `${item.id}`, label: `${item.accountName}` };
      //             });
      //             setTwitterBotList(newArray);
      //             dispatch(resetGetBotOperators());
      //       }
      // }, [getBotOperatorRes]);

      useEffect(() => {
            if (getTargetAccountsRes.statusCode == 200) {
                  //console.log(getTargetAccountsRes.data.accounts);
                  setTargetAccountList([
                        ...getTargetAccountsRes.data.accounts
                  ]);
                  dispatch(resetGetTargetAccounts());

                  setLoadingTwtUsers(false);
            }
      }, [getTargetAccountsRes]);

      useEffect(() => {
            if(getTargetAccountsFailed.statusCode == 401){
                  dispatch(resetGetTargetAccountsFailed());
                  toast.error(getTargetAccountsFailed.message, {autoClose:6000,onClose: () => {
                        dispatch(emptyRedux());
                        logout();
                        navigate("/login");
                  }});
            }
      }, [getTargetAccountsFailed]);

      useEffect(() => {
            if (getHashtagRes.statusCode == 200) {
                  console.log("getHashtagRes", getHashtagRes);
                  
                  setHashtagList([
                        ...getHashtagRes.data.hashtags
                  ]);
                  dispatch(resetGetHashtag());
                  setLoadingTwtHashTag(false);
            }
      }, [getHashtagRes]);

      useEffect(() => {
            console.log("createTargetAccountRes", createTargetAccountRes);
            if (createTargetAccountRes.statusCode == 200) {
                  toast.success('New target account added successfully.', {autoClose:3000});
                  dispatch(resetCreateTargetAccount());
                  dispatch(getTargetAccounts());
            }
            let loadingBtnAry = loadingBtn; 
            loadingBtnAry.targetaccount = false;
            setLoadingBtn({
                  ...loadingBtnAry
            });
      }, [createTargetAccountRes]);

      useEffect(() => {
            console.log(createTargetAccountFailed);
            if(createTargetAccountFailed.statusCode == 400){
                  toast.error(createTargetAccountFailed.message, {autoClose:5000});
                  dispatch(resetCreateTargetAccountFailed());
            }
      }, [createTargetAccountFailed]);

      useEffect(() => {
            console.log("updateTargetAccountStatusRes", updateTargetAccountStatusRes);
            if (updateTargetAccountStatusRes && updateTargetAccountStatusRes.statusCode == 200) {
                  dispatch(resetUpdateTargetAccountStatus());
                  toast.success('Status updated successfully', {autoClose:3000});
            }
      }, [updateTargetAccountStatusRes]);

      useEffect(() => {
            if (createHashtagRes.statusCode == 200) {
                  dispatch(resetCreateHashtag());
                  dispatch(getHashtag());
            }
            let loadingBtnAry = loadingBtn; 
            loadingBtnAry.hashtag = false;
            setLoadingBtn({
                  ...loadingBtnAry
            });
      }, [createHashtagRes]);

      useEffect(() => {
            if (updateHashtagStatusRes && updateHashtagStatusRes.statusCode == 200) {
                  dispatch(resetUpdateHashtagStatus());
                  toast.success('Status updated successfully', {autoClose:3000});
            }
      }, [updateHashtagStatusRes]);

      useEffect(() => {
            if (deleteTargetAccountRes.statusCode == 200) {
                  dispatch(getTargetAccounts());
                  toast.success('Target account removed.', {autoClose:3000});
                  dispatch(resetDeleteTargetAccount());
            }
            setTimeout(() => {
                  setModalShow(false);
            }, 1000);
      }, [deleteTargetAccountRes]);


      useEffect(() => {
            if (deleteHashtagRes.statusCode == 200) {
                  dispatch(getHashtag());
                  toast.success('Hashtag removed.', {autoClose:3000});
                  dispatch(resetDeleteHashtag());
            }
            setTimeout(() => {
                  setModalShow(false);
            }, 1000);
      }, [deleteHashtagRes]);


      const handleChange = (input, event) => {
            var value = event.target.value;

            if(input == "user_name"){
                  setUsernameInput(value);
            }

            if(input == "hashtag_name"){
                  setHashtagInput(value);
            }
      };

      const targetAccountSubmit = (e) => {
            e.preventDefault();

            let loadingBtnAry = loadingBtn; 
            loadingBtnAry.targetaccount = true;
            setLoadingBtn({
                  ...loadingBtnAry
            });
            

            if(usernameInput != ""){
                  let data = {
                        "bot_id": 1,
                        "user_name": usernameInput
                  };

                  dispatch(createTargetAccount(data));
                  setUsernameInput("");
            }
            else{
                  toast.error('Validation unsuccessful. The value is invalid or empty.', {autoClose:3000});
            }
      };

      const changeTwitterStatus = (account_id, e, iteration) => {
            
            let data = {
                  "targetaccount_id": account_id,
                  "accountStatus": e.target.checked ? 1 : 0
            };

            dispatch(updateTargetAccountStatus(data));

            let targetAccountListAry = targetAccountList;

            targetAccountListAry[iteration].accountStatus = e.target.checked ? 1 : 0;

            setTargetAccountList([
                  ...targetAccountList
            ]);
      };

      const removeTargetAccountRecord = (targetaccount_id, iteration) => {

            setRemoveDataId(targetaccount_id);

            setRemoveDataType('TWITTER_TARGET_ACCOUNT');

            setModalShow(true);
      };

      const hashtagSubmit = (e) => {
            e.preventDefault();

            let loadingBtnAry = loadingBtn; 
            loadingBtnAry.hashtag = true;
            setLoadingBtn({
                  ...loadingBtnAry
            });

            if(hashtagInput != ""){

                  let data = {
                        "hashtag_name": hashtagInput
                  };

                  dispatch(createHashtag(data));

                  setHashtagInput("");

                  toast.success('New hashtag has been added successfully.', {autoClose:3000});
            }
            else{
                  toast.error('Validation unsuccessful. The value is invalid or empty.', {autoClose:3000});
            }
      };

      const changeHashtagStatus = (hashtag_id, e, iteration) => {
            
            let data = {
                  "hashtag_id": hashtag_id,
                  "hashtagStatus": e.target.checked ? 1 : 0
            };

            dispatch(updateHashtagStatus(data));

            let hashtagListAry = hashtagList;

            hashtagListAry[iteration].hashtagStatus = e.target.checked ? 1 : 0;

            setHashtagList([
                  ...hashtagListAry
            ]);
      };

      const removeHashtagRecord = (hashtag_id, iteration) => {
            setRemoveDataId(hashtag_id);

            setRemoveDataType('TWITTER_HASHTAG');

            setModalShow(true);
      };

      const removeRecord = () => {

            console.log("removeDataType", removeDataType);

            console.log("removeDataId", removeDataId);

            if(removeDataType == "TWITTER_TARGET_ACCOUNT" && removeDataId !== 0){
                  let data = {
                        "data" : {
                              "target_account_id": removeDataId
                        }
                  };

                  setRemoveDataType("");
                  setRemoveDataId(0);

                  dispatch(deleteTargetAccount(data));
            }
            else if(removeDataType == "TWITTER_HASHTAG" && removeDataId !== 0){
                  let data = {
                        "data" : {
                              "hashtag_id": removeDataId
                        }
                  };
                  
                  setRemoveDataType("");
                  setRemoveDataId(0);

                  dispatch(deleteHashtag(data));
            }
            else{
                  toast.error('Something went wrong. Please try again!', {autoClose:3000});
            } 
      };

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft">
                                    <h3>Social media settings</h3>
                              </div>
                              <HeaderProfileArea/>
                        </div>
                  </header>
                  <div className="main_content">
                        <div className="social_settings fadein">
                              <div className="blk">
                                   
                                    <div className="tabls row">
                                          <div className="bls col-md-6">
                                          <h4>
                                          <img src={Round_twt} alt="" />
                                          <span>Targeted Twitter Account</span>
                                    </h4>
                                                <div className='greenbg'>
                                                <form onSubmit={targetAccountSubmit}>
                                                      <input type="text" name="user_name" placeholder='Enter twitter user name' onChange={(e) => handleChange("user_name", e)} value={usernameInput} />
                                                      <button type="submit" className={"custom_btn " + (loadingBtn.targetaccount ? "btn_loading" : "")}>
                                                            <span>Add</span>
                                                            <ButtonLoading />
                                                      </button>
                                                </form>
                                                      <Table responsive>
                                                            <thead>
                                                                  <tr>
                                                                        <th>ID</th>
                                                                        <th>User name</th>
                                                                        <th>User id</th>
                                                                        <th width="100">Status</th>
                                                                        <th width="100">Action</th>
                                                                  </tr>
                                                            </thead>
                                                            { loadingTwtUsers === true ? 
                                                                  <div className="posthistoryloader">
                                                                        <Loader/>
                                                                  </div>
                                                                  : ''
                                                            }
                                                            <tbody>
                                                            
                                                            {targetAccountList.length > 0 
                                                                  
                                                                  ? 
                                                                  
                                                                  targetAccountList.map((data, key) => {

                                                                        return (
                                                                              <tr key={key+1}>
                                                                                    <td>{key+1}</td>
                                                                                    <td>{data.accountUsername}</td>
                                                                                    <td>{data.accountId}</td>
                                                                                    <td>
                                                                                          <span className='togglebtn'>
                                                                                                <input 
                                                                                                type="checkbox" 
                                                                                                id={"targetAcSwitch"+data.id} 
                                                                                                defaultChecked={data.accountStatus == 1 ? true : false}
                                                                                                onChange={(e)=>changeTwitterStatus(data.id, e, key)}
                                                                                                />
                                                                                                <label htmlFor={"targetAcSwitch"+data.id}></label>
                                                                                          </span>
                                                                                    </td>
                                                                                    <td>
                                                                                          <button type="button" onClick={(e)=>removeTargetAccountRecord(data.id, e)}>
                                                                                                <img src={Delete} alt="" />
                                                                                          </button>
                                                                                    </td>
                                                                              </tr>
                                                                        )
                                                                  }) 
                                                                  
                                                                  : 
                                                                  ''
                                                            }

                                                            { loadingTwtUsers === false && targetAccountList.length === 0 ? 

                                                                  <tr>
                                                                        <td colSpan="5" className="text-center">
                                                                        The list of targeted Twitter accounts is empty.
                                                                        </td>
                                                                  </tr>
                                                                  : ''
                                                            }
                                                            </tbody>
                                                      </Table>
                                                </div>
                                          </div>
                                          <div className="bls col-md-6">
                                          <h4>
                                          <img src={Round_twt} alt="" />
                                          <span>Targeted Twitter Hashtag</span>
                                    </h4>
                                    <div className='greenbg'>
                                                <form onSubmit={hashtagSubmit}>
                                                      <input type="text" name="hashtag_name"  placeholder='Enter hashtag' onChange={(e) => handleChange("hashtag_name", e)} value={hashtagInput} />
                                                      <button type="submit" className={"custom_btn " + (loadingBtn.hashtag ? "btn_loading" : "")}>
                                                            <span>Add</span>
                                                            <ButtonLoading />
                                                      </button>
                                                </form>
                                                <Table responsive>
                                                      <thead>
                                                            <tr>
                                                                  <th>ID</th>
                                                                  <th>Hashtag</th>
                                                                  <th width="100">Status</th>
                                                                  <th width="100">Action</th>
                                                            </tr>
                                                      </thead>
                                                      { loadingTwtHashTag === true ? 
                                                            <div className="posthistoryloader">
                                                                  <Loader/>
                                                            </div>
                                                            : ''
                                                      }
                                                      <tbody>
                                                      {hashtagList.length > 0 ? hashtagList.map((data, key) => {
                                                            return (
                                                            <tr key={key+1}>
                                                                  <td>{key+1}</td>
                                                                  <td>{data.hashtagName}</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input 
                                                                              type="checkbox" 
                                                                              id={"hashtagSwitch"+data.id} 
                                                                              defaultChecked={data.hashtagStatus == 1 ? true : false}
                                                                              onChange={(e)=>changeHashtagStatus(data.id, e, key)}
                                                                              />
                                                                              <label htmlFor={"hashtagSwitch"+data.id}></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button type="button" onClick={(e)=>removeHashtagRecord(data.id, e)}>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                            );
                                                      }) : 
                                                      ''
                                                      }

                                                      { loadingTwtHashTag === false && hashtagList.length === 0 ? 

                                                            <tr>
                                                                  <td colSpan="4" className="text-center">No Twitter hashtags were added.</td>
                                                            </tr>
                                                      : ''
                                                      }
                                                      </tbody>
                                                </Table>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              {/* <div className="blk">
                                    <h4>
                                          <img src={Round_fb} alt="" />
                                          <span>Facebook</span>
                                    </h4>
                                    <div className="tabls">
                                          <div className="bls">
                                                <form>
                                                      <input type="text" placeholder='Enter user name' />
                                                      <input type="submit" value="Add" />
                                                </form>
                                                <Table responsive>
                                                      <thead>
                                                            <tr>
                                                                  <th>ID</th>
                                                                  <th>User name</th>
                                                                  <th>User id</th>
                                                                  <th>Status</th>
                                                                  <th>Action</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td>1244397</td>
                                                                  <td>geroge-001</td>
                                                                  <td>asd14d651qa4qs21d3as</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td>5614651</td>
                                                                  <td>geroge-002</td>
                                                                  <td>g4fw582471adfjtwe9514</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                      </tbody>
                                                </Table>
                                          </div>
                                          <div className="bls">
                                                <form>
                                                      <input type="text" placeholder='Select hashtag' />
                                                      <input type="submit" value="Add" />
                                                </form>
                                                <Table responsive>
                                                      <thead>
                                                            <tr>
                                                                  <th>ID</th>
                                                                  <th>Hashtag</th>
                                                                  <th>Status</th>
                                                                  <th>Action</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td>1244397</td>
                                                                  <td>#geroge-001</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td>5614651</td>
                                                                  <td>#geroge-002</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                      </tbody>
                                                </Table>
                                          </div>
                                    </div>
                              </div>
                              <div className="blk">
                                    <h4>
                                          <img src={Round_insta} alt="" />
                                          <span>Instagram</span>
                                    </h4>
                                    <div className="tabls">
                                          <div className="bls">
                                                <form>
                                                      <input type="text" placeholder='Enter user name' />
                                                      <input type="submit" value="Add" />
                                                </form>
                                                <Table responsive>
                                                      <thead>
                                                            <tr>
                                                                  <th>ID</th>
                                                                  <th>User name</th>
                                                                  <th>User id</th>
                                                                  <th>Status</th>
                                                                  <th>Action</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td>1244397</td>
                                                                  <td>geroge-001</td>
                                                                  <td>asd14d651qa4qs21d3as</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td>5614651</td>
                                                                  <td>geroge-002</td>
                                                                  <td>g4fw582471adfjtwe9514</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                      </tbody>
                                                </Table>
                                          </div>
                                          <div className="bls">
                                                <form>
                                                      <input type="text" placeholder='Select hashtag' />
                                                      <input type="submit" value="Add" />
                                                </form>
                                                <Table responsive>
                                                      <thead>
                                                            <tr>
                                                                  <th>ID</th>
                                                                  <th>Hashtag</th>
                                                                  <th>Status</th>
                                                                  <th>Action</th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <td>1244397</td>
                                                                  <td>#geroge-001</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td>5614651</td>
                                                                  <td>#geroge-002</td>
                                                                  <td>
                                                                        <span className='togglebtn'>
                                                                              <input type="checkbox" id="switch9" />
                                                                              <label htmlFor="switch9"></label>
                                                                        </span>
                                                                  </td>
                                                                  <td>
                                                                        <button>
                                                                              <img src={Delete} alt="" />
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                      </tbody>
                                                </Table>
                                          </div>
                                    </div>
                              </div> */}
                        </div>
                  </div>
                  <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className='connet_modal cm-bg-none fadein'
                  >
                        <div className="bgs">
                              <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                          Do you want to remove this record?
                                    </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                    <div className="frm">
                                          <button type='button' className="s_btn" onClick={() => removeRecord()}>
                                                <span> Confirm</span>
                                                <ButtonLoading />
                                          </button>
                                    </div>
                                    <div className="frm">
                                          <button type='button' className="sr_btn" onClick={() => setModalShow(false)}>
                                                <span> Cancel</span>
                                                <ButtonLoading />
                                          </button>
                                    </div>
                              </Modal.Body>
                        </div>
                  </Modal>
                  <ToastContainer />
            </div>
      )
}


export default SocialMediaSettings;