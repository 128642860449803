import { React, useEffect, useRef, useState } from 'react';
import HeaderProfileArea from './shared/HeaderProfileArea';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonLoading } from './shared/Buttonloading';

const EditAccount = () => {

      const dispatch = useDispatch();
      const navigate = useNavigate();
      
      const [isActive, setActive] = useState(false);
      const handleLoginSubmit = async (event) => {
            event.preventDefault();
            setTimeout(() => {
                  const email = event.target.elements.email.value;
                  const password = event.target.elements.password.value;
                  console.log(password, email);
                  if(password == ""){
                        console.log('fill all fields');
                        return;
                  }
                  let data = {
                        "email": email,
                        "password": password
                  }
                  // dispatch(logIn(data));
            }, 1500);
      }

      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft">
                                    <h3>Edit Account</h3>
                              </div>
                              <HeaderProfileArea/>
                        </div>
                  </header>
                  <div className="main_content">
                        <div className="social_settings fadein">
                              <div className="tabls row log_form">
                                    <form onSubmit={handleLoginSubmit}>
                                          <input type="text" name="access_token"  placeholder='Enter Access Token' />
                                          <input type="text" name="access_secret"  placeholder='Enter Access Secret' />
                                          <input type="text" name="app_key"  placeholder='Enter App Key' />
                                          <input type="text" name="app_secret"  placeholder='Enter App Secret' />
                                          <div className="l_frm">
                                                <button type='submit' 
                                                      className={isActive ?  "btn_loading" : null }
                                                >
                                                <span> Save</span>
                                                      <ButtonLoading />
                                                </button>
                                                {/* <ErrorMessage /> */}
                                          </div>
                                    </form>
                              </div>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}


export default EditAccount;