import { React, useEffect, useRef, useState } from 'react';
import Profile from '../assets/images/Profile.png';
import Round_twt from '../assets/images/twitter-circle.png';
import Round_fb from '../assets/images/Facebook-circle.png';
import Round_insta from '../assets/images/insta-circle.png';
import Table from 'react-bootstrap/Table';
import Delete from '../assets/images/delete.png';
import HeaderProfileArea from './shared/HeaderProfileArea';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonLoading } from './shared/Buttonloading';
import { useDispatch, useSelector } from 'react-redux';
import { getBotOperators, resetGetBotOperators, createTargetAccount, resetCreateTargetAccount, getTargetAccounts, resetGetTargetAccounts, updateTargetAccountStatus, resetUpdateTargetAccountStatus, getHashtag, resetGetHashtag, createHashtag, resetCreateHashtag, updateHashtagStatus, resetUpdateHashtagStatus, deleteHashtag, resetDeleteHashtag, deleteTargetAccount, resetDeleteTargetAccount, resetGetTargetAccountsFailed, resetCreateTargetAccountFailed } from '../actions/twitterAction';
import { logout } from '../helpers/userAuth';
import { useNavigate } from 'react-router-dom';
import { emptyRedux } from '../actions/twitterAction';

const GeneDocs = () => {

      const dispatch = useDispatch();
      const navigate = useNavigate();
      
      return (
            <div className="dashboard">
                  <header>
                        <div className="flex">
                              <div className="lft">
                                    <h3>Gene Docs</h3>
                              </div>
                              <HeaderProfileArea/>
                        </div>
                  </header>
                  <div className="main_content">
                        <div className="social_settings fadein">
                              <Table responsive>
                                    <thead>
                                          <tr>
                                                <th>ID</th>
                                                <th>User name</th>
                                                <th>Tweet Remaining</th>
                                                <th>Tweet limit</th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          <tr>
                                                <td>1</td>
                                                <td>Blaze 01</td>
                                                <td>45</td>
                                                <td>50</td>
                                          </tr>
                                    </tbody>
                              </Table>
                        </div>
                  </div>
                  <ToastContainer />
            </div>
      )
}


export default GeneDocs;