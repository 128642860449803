import { Fragment, React, useEffect, useState, useRef } from 'react';
import { Sidebar } from '../components/Sidebar';

const DefaultLayout = ({ children }) => {
    return (
        <Fragment>        
            <Sidebar />
            <div className="das_wrapper">
                    
                {children}
            </div>
        </Fragment>
    );
}

export default DefaultLayout;