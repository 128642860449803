import React, { useState } from "react";
import {Link, NavLink } from "react-router-dom";
import Logo from '../assets/images/logo_w.png';
import Menu1 from '../assets/images/dash_icon.png';
import Menu2 from '../assets/images/act_icon.png';
import Menu3 from '../assets/images/post_icon.png';
import Menu4 from '../assets/images/ra_icon.png';
import Settings from '../assets/images/settings.png';
import Drop from '../assets/images/d_arrow.png';
import Menu from '../assets/images/menu.png';
import Close from '../assets/images/close.png';

export const Sidebar = () => {

      const [show, setShow] = useState(true);
      const [open, setOpen] = useState(true);

      return (
            <div className="sidebar" id={open ? "" : "open"}>
                  <button type="submit" className="menu" onClick={() => setOpen(!open)}>
                        <img src={Menu} alt="" />
                  </button>
                  <button type="submit" className="close" onClick={() => setOpen(!open)}>
                        <img src={Close} alt="" />
                  </button>
                  <div className="logo">
                        <NavLink to="/dashboard">
                              <img src={Logo} alt="" />
                        </NavLink>
                  </div>
                  <div className="manus">
                        <div className="menu_list">
                              <ul>
                                    <li>
                                          <NavLink to="/dashboard" className={({ isActive }) => (isActive ? "active" : '')}>
                                                <i>
                                                      <img src={Menu1} alt="" />
                                                </i>
                                                <span>Dashboard</span>
                                          </NavLink>
                                    </li>
                                    <li>
                                          <Link to="#" onClick={() => setShow(!show)} className={show ? "" : "active"}>
                                                <i>
                                                      <img src={Menu2} alt="" />
                                                </i>
                                                <span>Accounts</span>
                                                <i className="drop">
                                                      <img src={Drop} alt="" />
                                                </i>
                                          </Link>
                                          <ul className="sub_menu">
                                                {/* <li>
                                                      <NavLink to="/connect-account" className={({ isActive }) => (isActive ? "active" : '')}>
                                                            Connect Account
                                                      </NavLink>
                                                </li> */}
                                                <li>
                                                      <NavLink to="/manage-account" className={({ isActive }) => (isActive ? "active" : '')}>
                                                            Manage Accounts
                                                      </NavLink>
                                                </li>
                                          </ul>
                                    </li>
                                    <li>
                                          <NavLink to="/create-post" className={({ isActive }) => (isActive ? "active" : '')}>
                                                <i>
                                                      <img src={Menu3} alt="" />
                                                </i>
                                                <span>Create Posts</span>
                                          </NavLink>
                                    </li>
                                    <li>
                                          <NavLink to="/live-feeds" className={({ isActive }) => (isActive ? "active" : '')}>
                                                <i>
                                                      <img src={Menu4} alt="" />
                                                </i>
                                                <span>Live Feeds</span>
                                          </NavLink>
                                    </li>
                                    <li>
                                          <NavLink to="/social-media-settings" className={({ isActive }) => (isActive ? "active" : '')}>
                                                <i>
                                                      <img src={Settings} alt="" />
                                                </i>
                                                <span>Social Media Settings</span>
                                          </NavLink>
                                    </li>
                              </ul>
                        </div>
                  </div>
            </div>
      );
}
