import api from '../services/api';
import apiWithJwt from '../services/apiWithJwt';
import { types } from '../store/constants/types';


export const askOpenAi = (data, rid) => {
  return (dispatch) => {
    const apiWithToken = apiWithJwt(localStorage.token);
    apiWithToken.post('/openai/chatgpt', data)
      .then((response) => {
        dispatch({ type: types.GET_OPENAI_SUCCESS, payload: response.data, rid: rid});
      })
      .catch((error) => {
        dispatch({ type: types.GET_OPENAI_FAILED, payload: (error.response.data), rid: rid});
      });
  };
};

export const resetAskOpenAi = () => {
  return dispatch => {
     dispatch({type : types.GET_OPENAI_SUCCESS, payload : [], rid: -1})
}}