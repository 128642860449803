import axios from 'axios';
import { configPaths } from '../config';

const api = axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': 'application/json',
  },
});

// export const apiAsBlob = axios.create({
//   baseURL: configPaths.apiPath,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   responseType: 'blob',
// });

export default api;