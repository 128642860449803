import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import { twitterReducer } from './reducers/twitter-reducer';

const loggerMiddleware = createLogger();

export const store = configureStore({
  reducer:{
    twitterReducer
},middleware : [thunkMiddleware,loggerMiddleware]})