import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const AdminRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('token') !== null;
  
    if(!isAuthenticated){
        return <Navigate to="/login"/>;
    }else{
        return children;
    }
};

export default AdminRoute;