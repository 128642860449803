import {Routes, Route, createPath} from "react-router-dom";
import Login from './components/Login.jsx';
import Dashboard from "./components/Dashboard.jsx";
import ConnectAccount from "./components/ConnectAccount.jsx";
import DefaultLayout from "./layout/defaultLayout.jsx";
import ManageAccount from "./components/ManageAccount.jsx";
import CreatePost from "./components/CreatePost.jsx";
import RecentActivity from "./components/RecentActivity.jsx";
import SocialMediaSettings from "./components/SocialMediaSettings.jsx";
import PageNotFound from "./components/PageNotFound.jsx";
import AdminRoute from "./layout/adminRoute.jsx";
import GeneDocs from "./components/GeneDocs.jsx";
import EditAccount from "./components/EditAccount.jsx";

function App() {
  return (
    <div className="gene-layout">
      <Routes >
        <Route path="/login" element={ <Login /> } />
        <Route path="/" element={ 
          <AdminRoute>
            <DefaultLayout>
              <Dashboard />
            </DefaultLayout>
          </AdminRoute>
        }/>
        <Route path="/dashboard" element={ 
          <AdminRoute>
            <DefaultLayout>
              <Dashboard />
            </DefaultLayout>
          </AdminRoute> 
        }/>
        <Route path="/connect-account" element={ 
          <AdminRoute>
            <DefaultLayout>
              <ConnectAccount />
            </DefaultLayout> 
          </AdminRoute>
        } />
        <Route path="/manage-account" element={ 
          <AdminRoute>
            <DefaultLayout>
              <ManageAccount />
            </DefaultLayout> 
          </AdminRoute>
        }/>
        <Route path="/edit-account" element={ 
          <AdminRoute>
            <DefaultLayout>
              <EditAccount />
            </DefaultLayout> 
          </AdminRoute>
        }/>
        <Route path="/create-post" element={ 
          <AdminRoute>
            <DefaultLayout>
              <CreatePost />
            </DefaultLayout>
          </AdminRoute> 
        } />
        <Route path="/live-feeds" element={ 
          <AdminRoute>
            <DefaultLayout>
              <RecentActivity />
            </DefaultLayout> 
          </AdminRoute> 
        } />
        <Route path="/social-media-settings" element={ 
          <AdminRoute>
            <DefaultLayout>
              <SocialMediaSettings />
            </DefaultLayout> 
          </AdminRoute> 
        } />

        <Route path="/gene-docs" element={ 
          <AdminRoute>
            <DefaultLayout>
              <GeneDocs />
            </DefaultLayout> 
          </AdminRoute> 
        } />

        <Route path='*' element={ <PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
