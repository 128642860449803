import axios from 'axios';
import { configPaths } from '../config';

const apiWithJwt = (bearerToken) => axios.create({
  baseURL: configPaths.apiPath,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearerToken}`
  },
});

export default apiWithJwt;