import { React, useEffect, useRef, useState } from 'react';
import Profile from '../../assets/images/Profile.png';
import { logout } from '../../helpers/userAuth';
import { NavLink, useNavigate } from 'react-router-dom';
import { emptyRedux } from '../../actions/twitterAction';
import { useDispatch } from 'react-redux';

const HeaderProfileArea = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function logoutUser (event){
        event.preventDefault();
        dispatch(emptyRedux());
        logout();
        navigate("/login");
    }

    return (
        <div className="rft">
            <div className="txt">
                    <h5>{localStorage.username}</h5>
                    <a className='text-decoration-none' href='#' onClick={logoutUser}>Logout</a>
            </div>
            <div className="img">
                    <img src={Profile} alt="" />
            </div>
        </div>
    )
}


export default HeaderProfileArea;